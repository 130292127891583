import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/org/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Wizard = makeShortcode("Wizard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome! The pages that follow contain comprehensive documentation of the Beadswap ecosystem. If you are new to Beadswap, Please check out `}<Link to="/docs/v1/protocol-overview/how-beadswap-works/" mdxType="Link">{`Protocol Overview`}</Link>{` or `}<Link to="/docs/v1/faq" mdxType="Link">{`FAQ`}</Link>{` first.`}</p>
    <p>{`For a high-level overview of Beadswap, see the `}<Link to='/blog/' mdxType="Link">{`introductory blog post`}</Link>{`.`}</p>
    <h2 {...{
      "id": "developer-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#developer-links",
        "aria-label": "developer links permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer links`}</h2>
    <p>{`The Beadswap codebase open source components.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Beadswap/beadswap-v1-core"
        }}><inlineCode parentName="a">{`beadswap-v1-core`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Beadswap/Beadswap-V1-Periphery"
        }}><inlineCode parentName="a">{`beadswap-v1-periphery`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Beadswap/beadswap-org"
        }}><inlineCode parentName="a">{`beadswap-frontend`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Beadswap/sdk"
        }}><inlineCode parentName="a">{`beadswap-sdk`}</inlineCode></a></li>
    </ul>
    <h2 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting Started`}</h2>
    <Wizard mdxType="Wizard" />
    <p>{`Explore the sidebar to find more specific documentation covering other aspects of the protocol.`}</p>
    <h2 {...{
      "id": "reference-materials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reference-materials",
        "aria-label": "reference materials permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reference materials`}</h2>
    <p>{`Get to know the Beadswap SDK and smart contract API objects, methods, attributes, and responses.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="/docs/v1/SDK/getting-started/" mdxType="Link">SDK</Link>
      </li>
      <li parentName="ul">
        <Link to="/docs/v1/API/overview" mdxType="Link">API</Link>
      </li>
      <li parentName="ul">
        <Link to="/docs/v1/smart-contracts/factory" mdxType="Link">Smart Contracts</Link>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/whitepaper.pdf"
        }}>{`Whitepaper`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      